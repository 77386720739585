import Form from "../Components/Form";

function Contact() {


  return (
      <Form />
  )
}

export default Contact;